<template>
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" style="margin-top:65px;">
    <Breadcrumbs style="display: inline-block" v-if="canManageDealers" />

    <br v-if="!selectedUser || !canManageDealers" />

    <v-card class="border10 mx-3 my-3">
      <v-progress-linear v-if="!selectedUser" indeterminate></v-progress-linear>
      <v-skeleton-loader
        v-if="!selectedUser"
        type="table-heading, list-item-two-line, image, table-tfoot"
        style="margin: 25px"
      ></v-skeleton-loader>

      <v-container v-if="selectedUser" class="border10">
        <h3 style="margin-bottom: 25px">
          {{ $t("user.user") + " # " + selectedUser.id }}
          <v-icon :title="$t('user.welder_expired_title')" v-if="selectedUser.isWelderExpired" color="red">
            mdi-flag
          </v-icon>
        </h3>
        <h4 style="color: gray; margin-bottom: 10px" v-if="selectedUser.id != currentUser.id">
          {{ $t("users.lastLogin") + ": " + (selectedUser.lastLogin ? yyyymmddhhmm(selectedUser.lastLogin) : $t("users.lastLoginNever")) }}
        </h4>
        <h4 style="color: gray; margin-bottom: 25px" v-if="selectedUser.id != currentUser.id">
          {{ $t("users.lastActivity") + ": " + (selectedUser.lastActivity ? yyyymmddhhmm(selectedUser.lastActivity) : $t("users.lastActivityNever")) }}
        </h4>
        <v-row>
          <v-col
            :lg="showRolesColumn ? 4 : 6"
            md="6"
            sm="6"
            cols="12"
            class="border-right"
          >
            <v-row>
              <v-col cols="12">
               <v-form ref="usernameForm" v-model="validUsername">
                 
                <v-text-field
                  ref="emailInput"
                  :label="$t('users.email') + '*'"
                  :class="{ errored: selectedUser.errorUsername }"
                  dense
                  :rules="emailRules"
                  :error-messages="usernameErrors"
                  v-model="selectedUser.username"
                  :disabled="!canManageDealers"
                  maxlength="255"
                  required
                  :loading="isValidatingUsername"
                  @input="onUsernameChange()"
                  v-on:keydown.enter.prevent
                ></v-text-field>
              </v-form>

              <section v-if="!selectedUser.passwordSet && selectedUser.isActive">
                <div class="small-text" v-if="!selectedUser.passwordSentAt">
                  {{ $t('user.no_password_sent') }}
                </div>
                
                <v-btn @click="sendNewPassword" rounded color="primary" v-if="selectedUser.username && validUsername && !isValidatingUsername && !errorSavingUsername">
                    <v-icon>mdi-email-sync-outline</v-icon> 
                    <div style="text-transform:none;font-size:11px;" class="ml-5">{{ $t('user.send_new_password')}}</div>
                </v-btn>
                <div class="small-text" v-if="selectedUser.username && validUsername && selectedUser.passwordSentAt && !isValidatingUsername && !errorSavingUsername">
                  {{ $t('user.password_sent_at') + " " + yyyymmdd(new Date(selectedUser.passwordSentAt))  }}
                </div>
              </section>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  :label="$t('reportInfo.firstname')"
                  :class="{ errored: selectedUser.errorFirstname }"
                  dense
                  v-model="selectedUser.firstname"
                  :disabled="!canManageDealers"
                  maxlength="45"
                  hide-details
                  @change="onFirstNameChange()"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :label="$t('reportInfo.lastname')"
                  :class="{ errored: selectedUser.errorLastname }"
                  dense
                  v-model="selectedUser.lastname"
                  :disabled="!canManageDealers"
                  maxlength="45"
                  hide-details
                  @change="onLastNameChange()"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="selectedUser.communicationLanguage"
                  @change="onCommunicationLanguageChange"

                  :label="$t('user.communication_language')"
                  :items="languageList"
                  
                  dense
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <div>
                  <label
                    style="font-size: 12px;display:block;"
                    :class="{ errored: selectedUser.errorPhoto }"
                    >{{ $t("user.photo") }}</label>
                  <div
                    @dragover.prevent="dragOver"
                    @dragleave.prevent="dragLeave"
                    @drop.prevent="dropPhoto($event)"
                  >
                    <div class="request-container" v-if="!selectedUser.photo">
                      <div class="requests-anchor">
                          <img class="required-img">
                          <span class="wrappedfile">{{ $t('reportInfo.required') }}</span>
                      </div>
                      <div class="upload-btn-wrapper">
                        <v-btn
                          fab
                          color="#009f4d"
                          dark
                          v-on:click="$refs.photo.click();"
                        >
                          <v-progress-circular v-if="isSavingPhoto" color="white" indeterminate></v-progress-circular>
                          <v-icon v-else>mdi-cloud-upload</v-icon>
                        </v-btn>
                        <input
                          style="display:none;"
                          type="file"
                          name="file"
                          ref="photo"

                          v-on:change="handlePhotoUpload"
                          
                          accept="image/png, image/jpeg, image/heic, image/webp, image/gif"
                        />
                      </div>
                    </div>
                    <div class="request-container" v-if="selectedUser.photo">
                      <a :href="selectedUser.photo?.url?.download" target="_blank" class="requests-anchor">
                        <img v-if="selectedUser.photo?.url?.thumbnail != null" :src="selectedUser.photo?.url?.thumbnail">
                        <img v-else src="/assets/icon/pho-preview.svg">
                      </a>
                      <v-icon v-on:click="confirmPhotoDelete()" class="delete-photo-icon">mdi-close</v-icon>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <br/>

            <v-btn class="techno-green" v-on:click="requestedInstallerStatus = selectedUser.installerStatus;requestInstallerCardDialog = true" 
                   v-if="!selectedUser.isInstaller && !canModifyInstallers && userFunctions.find((uf) => uf.id == FUNCTIONS.ADD_INSTALLER)">
              <img src="/assets/icon/address-card-regular.svg" style="padding:10px;" width="50px;" height="50px;">
              <span>{{ $t("user.request_installer_card") }}</span>
            </v-btn>
            
            <v-checkbox
              v-model="selectedUser.isInstaller"
              :label="$t('users.installer')"
              @change="isInstallerChanged()"
              hide-details
              :disabled="!canModifyInstallers"
            ></v-checkbox>
            <span style="font-size:12px;font-style:italic;">{{ $t('user.installer_help') }}</span>

            <div
              v-if="selectedUser.isInstaller"
              class="alternate"
            >
              <label
                style="display: block; font-size: 14px; margin: 5px 0px 0px 5px"
                :class="{ errored: selectedUser.errorInstallerCard }"
                >{{ $t("user.installer_info") }}</label
              >
                <label
                  style="font-size: 12px; margin: 10px 5px 0px 10px"
                  :class="{ errored: selectedUser.errorInstallerCard }"
                  >{{ $t("user.installer_card") }}</label
                >
              <v-row>
                <v-col style="margin-left:10px;" >
                  <div
                    @dragover.prevent="dragOver"
                    @dragleave.prevent="dragLeave"
                    @drop.prevent="dropInstallerCard($event)"
                  >
                    <div class="request-container" v-if="!selectedUser.installerCard">
                      <div class="requests-anchor">
                          <img class="required-img">
                          <span class="wrappedfile">{{ $t('reportInfo.required') }}</span>
                      </div>
                      <div class="upload-btn-wrapper">
                        <v-btn
                          fab
                          color="#009f4d"
                          dark
                          :disabled="!canModifyInstallers"
                          v-on:click="$refs.installerCard.click();"
                        >
                          <v-progress-circular v-if="isSavingInstallerCard" color="white" indeterminate></v-progress-circular>
                          <v-icon v-else>mdi-cloud-upload</v-icon>
                        </v-btn>
                        <input
                          style="display:none;"
                          type="file"
                          name="file"
                          ref="installerCard"

                          v-on:change="handleInstallerCardUpload"
                          
                          accept="image/png, image/jpeg, image/heic, image/webp, image/gif"
                        />
                      </div>
                    </div>
                    <div class="request-container" v-else>
                      <a :href="selectedUser.installerCard?.url?.download" target="_blank" class="requests-anchor">
                        <img :src="selectedUser.installerCard.url.thumbnail" v-if="selectedUser.installerCard?.url?.thumbnail != null">
                        <img v-else src="/assets/icon/pho-preview.svg">
                      </a>
                      <v-icon v-if="canModifyInstallers" v-on:click="confirmInstallerCardDelete()" class="delete-photo-icon">mdi-close</v-icon>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-btn class="techno-green" v-on:click="requestedInstallerStatus = selectedUser.installerStatus;requestInstallerCardDialog = true;" 
                      v-if="!canModifyInstallers && userFunctions.find((uf) => uf.id == FUNCTIONS.ADD_INSTALLER) && selectedUser.installerStatus == INSTALLER_STATUS.APPRENTICE">
                <span>{{ $t("user.request_installer_status_change") }}</span>
              </v-btn>

              <v-row style="margin: 0px;">
                <v-col cols="6" style="padding: 0px 12px">
                  <v-text-field
                    v-model="selectedUser.installerCardNumber"
                    :disabled="!canModifyInstallers"
                    :label="$t('user.installer_card_number')"
                    maxlength="45"
                    
                    @change="installerCardNumberChanged()"
                  />
                </v-col>
                <v-col cols="6" style="padding: 0px 12px">
                  <v-select
                    v-model="selectedUser.installerStatus"
                    :disabled="!canModifyInstallers"
                    :items="installerStatuses"
                    :label="$t('user.installer_status')"
                    
                    @change="installerStatusChanged()"
                  />
                </v-col>
              </v-row>

              <v-row style="margin: 0px;">
                <v-col cols="6" style="padding: 0px 12px">
                  <v-dialog
                    ref="installerFormationDateModal"
                    v-model="installerFormationDateModal"
                    :disabled="!canModifyInstallers"
                    :return-value.sync="selectedUser.installerFormationDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        readonly
                        :disabled="!canModifyInstallers"
                        v-model="selectedUser.installerFormationDate"

                        :label="$t('users.installerFormationDate')"
                        
                        v-bind="attrs"
                        v-on="on"
                        style="margin-top: 25px"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :first-day-of-week="getComputedFirstDayOfWeek"
                      v-model="selectedUser.installerFormationDate"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="installerFormationDateModal = false"
                        >{{ $t("confirm.cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.installerFormationDateModal.save(
                            selectedUser.installerFormationDate
                          );
                          updateInstallerFormationDate(true);
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                
                <v-col cols="6" style="padding: 0px 12px">
                  <v-dialog
                    ref="installerLastImpressionModal"
                    v-model="installerLastImpressionModal"
                    :disabled="!canModifyInstallers"
                    :return-value.sync="selectedUser.installerCardLastImpression"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        readonly
                        :disabled="!canModifyInstallers"
                        v-model="selectedUser.installerCardLastImpression"

                        :label="$t('users.installerCardLastImpression')"
                        
                        v-bind="attrs"
                        v-on="on"
                        style="margin-top: 25px"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :first-day-of-week="getComputedFirstDayOfWeek"
                      v-model="selectedUser.installerCardLastImpression"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="installerLastImpressionModal = false"
                        >{{ $t("confirm.cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.installerLastImpressionModal.save(
                            selectedUser.installerCardLastImpression
                          );
                          updateInstallerCardLastImpression(true);
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              
              <v-row style="margin: 0px;">
                <v-col cols="6" style="padding: 0px 12px">
                  <v-select
                    v-model="selectedUser.installerApprovedBy1"
                    :disabled="!canModifyInstallers"
                    :items="!selectedUser.installerApprovedBy2 ?
                      engineers : 
                      engineers.filter(item => item.value != selectedUser.installerApprovedBy2)"
                    :label="$t('user.installer_approved_by_1')"

                    @change="installerApprovedBy1Changed()"
                  />
                </v-col>
                <v-col cols="6" style="padding: 0px 12px">
                  <v-select
                    v-if="selectedUser.installerApprovedBy1"
                    v-model="selectedUser.installerApprovedBy2"
                    :disabled="!canModifyInstallers"
                    :items="engineers.filter(item => item.value != selectedUser.installerApprovedBy1)"
                    :label="$t('user.installer_approved_by_2')"

                    @change="installerApprovedBy2Changed()"
                  />
                </v-col>
              </v-row>

              <v-checkbox
                v-model="selectedUser.isAutoAddedInstaller"
                @change="isAutoAddedInstallerChanged()"
                :disabled="!canManageDealers"
                hide-details
              >
              <template v-slot:label>
                <span style="font-size: 14px">{{ $t('users.auto_add_installer') }}</span>
              </template>
              </v-checkbox>
            </div>

            <v-checkbox
              v-model="selectedUser.isWelder"
              :label="$t('users.welder')"
              @change="isWelderChanged()"
              hide-details
              :disabled="!canManageDealers"
            ></v-checkbox>
            <span style="font-size:12px;font-style:italic;">{{ $t('user.welder_help') }}</span>

            <div v-if="selectedUser.isWelder" class="alternate">
              <label
                style="display: block; font-size: 14px; margin: 5px 0px 0px 5px"
                >{{ $t("user.welder_info") }}</label
              >
                <label
                  style="font-size: 12px; margin: 10px 5px 0px 10px"
                  :class="{ errored: selectedUser.errorWelderCard }"
                  >{{ $t("user.welder_card") }}</label
                >
              <v-row>
                <v-col style="margin-left:10px;" >
                  <div
                    @dragover.prevent="dragOver"
                    @dragleave.prevent="dragLeave"
                    @drop.prevent="dropWelderCard($event)"
                  >
                    <div class="request-container">
                      <div class="requests-anchor" v-if="selectedUser.welderCardCwbs.length == 0">
                          <img class="required-img">
                          <span class="wrappedfile">{{ $t('reportInfo.required') }}</span>
                      </div>
                      <div class="upload-btn-wrapper">
                        <v-btn fab color="#009f4d" dark v-on:click="$refs.welderCardCwb.click();" v-if="selectedUser.multipleWelderCardImages || selectedUser.welderCardCwbs.length == 0" :disabled="!canManageDealers">
                          <v-progress-circular v-if="isSavingWelderCard" color="white" indeterminate></v-progress-circular>
                          <v-icon v-else>mdi-cloud-upload</v-icon>
                        </v-btn>
                        <input style="display:none;" type="file" name="file" ref="welderCardCwb" v-on:change="handleWelderCardUpload" :disabled="!canManageDealers" accept="image/png, image/jpeg, image/heic, image/webp, image/gif, application/pdf"/>
                      </div>
                    </div>

                    <div class="request-container" style="max-width:calc(100% - 65px)"
                     v-for="(welderCardCwb, index) in selectedUser.welderCardCwbs"
                     v-bind:key="'C'+ index">
                      <a :href="welderCardCwb.url?.download" target="_blank" class="requests-anchor">
                        <img :src="welderCardCwb.url.thumbnail" v-if="welderCardCwb.url?.thumbnail != null">
                        <img v-else src="/assets/icon/doc-file.svg">
                        <span class="wrappedfile">{{ welderCardCwb.filename }}</span>
                      </a>
                      <v-icon v-on:click="confirmWelderCardDelete(index)" v-if="canManageDealers" class="delete-photo-icon">mdi-close</v-icon>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row style="margin: 0px">
                <v-col cols="6" style="padding: 0px 12px">
                  <v-dialog
                    ref="welderExpirationDateModal"
                    v-model="welderExpirationDateModal"
                    :return-value.sync="selectedUser.welderExpirationDateCwb"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        v-model="selectedUser.welderExpirationDateCwb"
                        :label="$t('users.welderExpirationDateCwb')"
                        :class="{ errored: selectedUser.errorExpirationDate }"
                        :disabled="!canManageDealers"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        style="margin-top: 25px"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :first-day-of-week="getComputedFirstDayOfWeek"
                      v-model="selectedUser.welderExpirationDateCwb"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="welderExpirationDateModal = false"
                        >{{ $t("confirm.cancel") }}</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.welderExpirationDateModal.save(
                            selectedUser.welderExpirationDateCwb
                          );
                          updateWelderExpirationDate(true);
                        "
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <div
                v-if="selectedUser.isWelderExpired"
                style="font-style: italic; color: red; padding: 0px 0px 5px 5px"
              >
                <v-icon :title="$t('user.welder_expired_title')" color="red">
                  mdi-flag
                </v-icon>
                {{$t('reportInfo.welderstatus_expired')}}
              </div>
            </div>
          </v-col>

          <v-col
            :lg="showRolesColumn ? 4 : 6"
            md="6"
            sm="6"
            cols="12"
          >
            <div v-if="selectedUser.isInstaller">
              <label for="dealerMachines" style="display: block;">
                {{ $t("user.default_dealer_machine") }}
              </label>
              <v-select
                dense
                hide-details

                :items="selectedUser.availableDealerMachines"
                :item-text="dealerMachine => dealerMachine.name + (dealerMachine.serialNumber ? ' - ' + dealerMachine.serialNumber : '')"
                
                item-value="id"
                v-model="selectedUser.dealerMachineId"
                style="margin-top:5px;"
                @change="dealerMachineChanged()"
              ></v-select>

              <br/>
            </div>

            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="selectedUser.phoneMobile"
                  maxlength="50"
                  :label="$t('user.phone_mobile')"
                  @change="phoneMobileChanged()"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="selectedUser.phoneOffice"
                  maxlength="50"
                  :label="$t('user.phone_office')"
                  @change="phoneOfficeChanged()"
                ></v-text-field>
              </v-col>
            </v-row>

            <label for="user-color" style="display: block">{{
              $t("user.color")
            }}</label>
            <v-color-picker
              id="user-color"
              mode="hexa"
              v-model="selectedUser.colorCode"
              @update:color="colorChanged()"
            ></v-color-picker>

            <v-checkbox
              v-if="canManageDealers"
              v-model="selectedUser.isActive"
              :label="$t('users.active')"
              @change="isActiveChanged()"
              style="width:fit-content;"
            ></v-checkbox>
          </v-col>

          <v-col
            lg="4"
            md="12"
            sm="12"
            cols="12"
            v-if="userFunctions.find((uf) => uf.id == FUNCTIONS.ACCESS_TO_ALL_DEALERS) || 
                  userFunctions.find((uf) => uf.id == FUNCTIONS.ACCESS_TO_ASSIGNED_DEALERS) || 
                  userFunctions.find((uf) => uf.id == FUNCTIONS.MANAGE_USER_ROLES)"
          >

            <label for="availableUserDealers" style="display: block">{{
                $t("dealers.title")
              }}</label>
              <v-autocomplete
                id="availableUserDealers"
                dense
                :items="availableUserDealers"
                item-text="name"
                item-value="id"
                return-object
                v-model="selectedUser.editDealers"
                multiple
                chips
                @change="dealerChanged()"
              ></v-autocomplete>
            
            <div v-if="showManageGroups">
              <label for="dealerGroups" style="display: block">{{ $t("group.group_dealer_explain") }}</label>
              <v-autocomplete
                id="dealerGroups"
                dense
                :disabled="userFunctions.find((uf) => uf.id == FUNCTIONS.MANAGE_INSTALLERS_AND_GROUP) == null"
                :items="dealerGroups"
                item-text="name"
                item-value="id"
                return-object
                v-model="selectedUser.editDealerGroups"
                multiple
                chips
                @change="dealerGroupChanged()"
              ></v-autocomplete>
            </div>

            <div v-if="userFunctions.find((uf) => uf.id == FUNCTIONS.MANAGE_INSTALLERS_AND_GROUP)">
              <label for="engineeringDepartments" style="display: block">{{ $t("general.engineering_departments_explain") }}</label>
              <v-autocomplete
                id="engineeringDepartments"
                dense
                :items="engineeringDepartments"
                item-text="name"
                item-value="id"
                return-object
                v-model="selectedUser.editEngineeringDepartments"
                multiple
                chips
                @change="engineeringDepartmentsChanged()"
              ></v-autocomplete>
            </div>

            <label style="display: block">{{ $t("user.user_group") }}</label>
              <div
                v-for="(d, index) in selectedUser.groupDealers"
                v-bind:key="'A' + index"
              >
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                      <a style="color:black"
                        v-bind="attrs"
                        v-on="on">
                        {{ d.name }}
                      </a>
                  </template>
                  <span><small>{{ d.linkedGroups }}</small></span>
                </v-tooltip>
              </div>

              <br/>

            <div v-if="userFunctions.find((uf) => uf.id == FUNCTIONS.MANAGE_USER_ROLES)">
              <h4>{{ $t("user.security") }}</h4>
              <label for="roles" style="display: block">{{
                $t("user.roles")
              }}</label>
              <v-autocomplete
                id="roles"
                dense
                :items="roles"
                item-text="name"
                return-object
                v-model="selectedUser.editRoles"
                multiple
                chips
                @change="roleChanged()"
              ></v-autocomplete>

              <label style="display: block">{{ $t("user.functions") }}</label>
              <div
                v-for="(f, index) in selectedUser.functions"
                v-bind:key="'B' + index"
              >
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                      <a style="color:black"
                        v-bind="attrs"
                        v-on="on">
                        {{ f.translatedName }}
                      </a>
                  </template>
                  <span><small>{{ f.linkedRoles }}</small></span>
                </v-tooltip>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <br />

    <!-- Confirm dialog generique -->
    <ConfirmDialog
      v-if="confirmDialog"
      v-model="confirmDialog"

      :title="confirmDialogTitle"
      :description="confirmDialogBody"

      :cancelName="confirmYesNo ? $t('reportInfo.no') : $t('confirm.cancel')"
      :cancelFunction="() => (confirmDialog = false)"
      :actionName="confirmYesNo ? $t('reportInfo.yes') : $t('confirm.ok')"
      :actionFunction="confirmDialogYes"
    />

    <!-- Confirm dialog si on tente de quitter la page actuelle avec des erreurs -->
    <ConfirmDialog
      v-model="confirmDialogRouteLeave"

      :title="$t('confirm.head')"
      :description="$t('customer.fields_error_message')"

      :cancelName="$t('reportInfo.no')"
      :cancelFunction="rejectRouteLeave"
      :actionName="$t('reportInfo.yes')"
      :actionFunction="resolveRouteLeave"
    />

    <!-- Request installer card dialog -->
    <v-dialog v-if="requestInstallerCardDialog" v-model="requestInstallerCardDialog" max-width="500">
      <v-card>
        <v-card-title>
          {{ selectedUser.isInstaller ? $t('user.request_installer_status_change') : $t('user.request_installer_card') }}
        </v-card-title>
        <v-card-text>{{ $t('user.request_dialog_user') + ((selectedUser.firstname ? selectedUser.firstname : '') + ' ' + (selectedUser.lastname ? selectedUser.lastname : ''))}}</v-card-text>
        <v-card-actions>
          <v-container>
            <v-row>
              <v-select
                v-model="requestedInstallerStatus"
                :items="installerStatuses"
                :label="$t('user.installer_status')"
              />
            </v-row>
            <v-row v-if="!selectedUser.photo">
              <label
                style="font-size: 12px; margin: 10px 5px 0px 10px"
                :class="{ errored: selectedUser.errorPhoto }"
                >{{ $t("user.photo") }}</label
              >
            </v-row>
            <v-row v-if="!selectedUser.photo">
              <v-col style="margin-left:10px;" >
                <div
                  @dragover.prevent="dragOver"
                  @dragleave.prevent="dragLeave"
                  @drop.prevent="dropPhoto($event)"
                >
                  <div class="request-container" v-if="!selectedUser.photo" :label="$t('user.photo')">
                    <div class="requests-anchor">
                        <img class="required-img">
                        <span class="wrappedfile">{{ $t('reportInfo.required') }}</span>
                    </div>
                    <div class="upload-btn-wrapper">
                      <v-btn
                        fab
                        color="#009f4d"
                        dark
                        v-on:click="$refs.photo.click();"
                      >
                        <v-progress-circular v-if="isSavingPhoto" color="white" indeterminate></v-progress-circular>
                        <v-icon v-else>mdi-cloud-upload</v-icon>
                      </v-btn>
                      <input
                        style="display:none;"
                        type="file"
                        name="file"
                        ref="photo"
                        v-on:change="handlePhotoUpload"
                        accept="image/png, image/jpeg, image/heic, image/webp, image/gif"
                      />
                    </div>
                  </div>

                  <div class="request-container" v-if="selectedUser.photo">
                    <a :href="selectedUser.photo.url" target="_blank" class="requests-anchor">
                      <img :src="selectedUser.photo.thumbnail" v-if="selectedUser.photo.thumbnail != null">
                      <img v-if="selectedUser.photo.thumbnail == null" src="/assets/icon/pho-preview.svg">
                      <span class="wrappedfile">{{ selectedUser.photo.name }}</span>
                    </a>
                    <v-icon v-on:click="confirmPhotoDelete()" class="delete-photo-icon">mdi-close</v-icon>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="requestInstallerCardDialog = false;">{{ $t("user.add_user_cancel") }}</v-btn>
          <v-btn :disabled="!selectedUser.photo || !requestedInstallerStatus || (selectedUser.isInstaller && requestedInstallerStatus == selectedUser.installerStatus)" color="techno-green" slot="end" 
                @click="selectedUser.isInstaller ? sendRequestStatusChange() : sendRequestInstallerCard()">{{ $t("user.request_dialog_send") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="showConfirmation" timeout="15000">
          {{ $t("email.new_password_sent") }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="green"
          text
          v-bind="attrs"
          @click="showConfirmation = false;"
        >
          {{ $t("general.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import UserService from "@/services/user.service";
import Breadcrumbs from "@/components/Breadcrumbs";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog";
import FileService from "@/services/file.service";

import { sortByProperty, sortByProperties, updateFunctionTranslation, getLanguage, parseError, getFirstDayOfWeek } from "@/utils/utils";
import { emailRules, yyyymmdd, yyyymmddhhmm, capitalizeFirstLetter, isTechnoPieuxEmail } from "@/utils/validation";
import { languageList } from "@/utils/languages";
import { ROLES, FUNCTIONS, INSTALLER_STATUS } from "@/enums";

const ConfirmAction = Object.freeze({ DeleteWelderCard: 1, DeleteInstallerCard: 2, DeletePhoto: 3, DisableOwnUser: 4 });

export default {
  components: {
    Breadcrumbs,
    ConfirmDialog,
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
      currentUser: (state) => state.currentUser,
      countries: (state) => state.countries,
      userFunctions: (state) => state.userFunctions,
      userRoles: (state) => state.userRoles,
    }),
    ...mapGetters({
      canManageDealers: 'canManageDealers',
      canModifyInstallers: 'canModifyInstallers',
      canManageSecurity: 'canManageSecurity'
    }),
    showRolesColumn() {
      return this.userFunctions.find((uf) => uf.id == this.FUNCTIONS.ACCESS_TO_ALL_DEALERS) || 
             this.userFunctions.find((uf) => uf.id == this.FUNCTIONS.ACCESS_TO_ASSIGNED_DEALERS) || 
             this.userFunctions.find((uf) => uf.id == this.FUNCTIONS.MANAGE_USER_ROLES);
    },
    showManageGroups() {
      // On cache "Gère ces groupes des concessionnaires" pour Dealer, Certified installer, Advanced certified installer, Apprentice
      if (this.userFunctions.find((uf) => uf.id == FUNCTIONS.MANAGE_INSTALLERS_AND_GROUP) != null){
        if (this.userRoles != null) {
          let shouldHide = this.userRoles.find(ur => ur.id == ROLES.DEALER ||
                                                    ur.id == ROLES.INSTALLER ||
                                                    ur.id == ROLES.ADVANCED_INSTALLER ||
                                                    ur.id == ROLES.APPRENTICE);
          return !shouldHide;
        }
      }
      return false;
    },
    getComputedFirstDayOfWeek() {
      return getFirstDayOfWeek();
    },
    installerStatuses() {
      return [  
        {value: "certified", text: this.$t("enums.installer_status.certified")},
        {value: "apprentice", text: this.$t("enums.installer_status.apprentice")}
      ]
    },
    languageList: languageList
  },
  data() {
    return {
      selectedUser: null,
      selectedUserId: null,
      availableUserDealers: [],
      roles: null,
      engineers: null,
      dealers: [],
      autocompleteList: [],
      installerFormationDateModal: false,
      installerLastImpressionModal: false,
      welderExpirationDateModal: false,

      confirmDialogRouteLeave: false,
      resolveRouteLeave: null,
      rejectRouteLeave: null,

      confirmDialog: false,
      confirmDialogTitle: null,
      confirmDialogBody: null,
      confirmAction: null,
      confirmYesNo: true,
      emailRules: emailRules,
      validEmails: false,
      validUsername: false,
      showConfirmation: false,
      isValidatingUsername: false,
      errorSavingUsername: false,
      usernameValidationTimeout: undefined,
      isSavingWelderCard: false,
      isSavingInstallerCard: false,
      isSavingPhoto: false,
      hasSelectedFile: false,
      isFileValid: false,
      savingFile: false,
      dropped: 0,
      requestInstallerCardDialog: false,
      requestedInstallerStatus: null,
      dealerGroups: [],
      engineeringDepartments: [],
      FUNCTIONS: FUNCTIONS,
      INSTALLER_STATUS: INSTALLER_STATUS,
      welderCardToDeleteIndex: null,
      usernameErrors: []
    };
  },
  mounted() {
    console.log("User mounted");
    this.initializeUser(this.$route.params.id);
  },
  beforeRouteUpdate(to, from, next) { // eslint-disable-line
    console.log('beforeRouteUpdate', from.params.id, to.params.id, this.$route.params.id)
    this.initializeUser(to.params.id);

    next();
  },
  beforeDestroy() {
    console.log('User unmounted')
    //clearInterval(this.initMapInterval);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.selectedUser || this.validUsername) {
      next();
    } else {
      if (this.$refs.usernameForm) {
        this.$refs.usernameForm.validate();
      }
      this.confirmDialogRouteLeave = true;

      new Promise((resolve, reject) => {
          this.resolveRouteLeave = resolve;
          this.rejectRouteLeave = reject;
        })
        .then((res) => {
          console.log(res);
          next();
        })
        .catch((res) => {
          console.log(res);
          next(false);
        })
        .then((res) => { // eslint-disable-line
          this.confirmDialogRouteLeave = false;
        })
    }
  },
  methods: {
    ...mapMutations([
      'openToast',
      'showErrorMessage',
      'updateUserAutoAddInstaller'
    ]),
    ...mapActions([ 'saveUserChanges' ]),

    setSelectedUserAction(userId) {
        console.log("setSelectedUserAction. Selected: " + this.selectedUserId + ", Setting: " + userId);
        this.setSelectedUser(null);
        this.setSelectedUserId(userId);

        if (this.selectedUserId > 0) {
          this.retrieveUserData();
        }
    },
    setSelectedUserId(id) {
      this.selectedUserId = id;
      console.log("Setting selected user id to " + id);
    },
    setSelectedUser(user) {
      console.log('setSelectedUser mutation', user);
      this.selectedUser = user;

      if (user) {
        this.resetUserChanges(this.selectedUser);
        this.resetUserErrors(this.selectedUser);

        this.formatUser(user);
      }
    },
    resetUserChanges(user) {
      user.changes = {
        addedDealers: [],
        deletedDealers: [],
        addedRoles: [],
        deletedRoles: [],
        addedDealerGroups: [],
        deletedDealerGroups: [],
        addedEngineeringDepartments: [],
        deletedEngineeringDepartments: []
      };
    },
    updateDealerGroups() {
      console.log('updateDealerGroups')
      this.updateAvailableUserDealersNonGroup();
      this.updateAvailableUserDealerMachines();
      this.populateDealersTooltips();
    },
    retrieveUserData() {
      console.log("Retrieving user data...");
      var scope = this;

      UserService.getUserPageData(this.selectedUserId, getLanguage())
        .then(function(response) {
          console.log('getUserPageData', response.data);

          scope.setSelectedUser(response.data.user);
          scope.setRoles(response.data.roles);
          scope.setEngineers(response.data.engineers);
          scope.dealers = response.data.dealers;
          scope.dealerGroups = response.data.dealerGroups;
          scope.engineeringDepartments = response.data.engineeringDepartments;
          scope.updateDealerGroups();
        })
        .catch(function(error) {
          // handle error
          let errorMsg = parseError(error);
          scope.showErrorMessage(errorMsg);

          if (error.toString().includes("401")) {
            scope.logout();
          } else {
            scope.$router.push("/");
          }
        })
    },
    saveUserChanges() {
      var scope = this;
      return new Promise((resolve, reject) => {
        console.log(scope.selectedUser.changes);

        UserService.saveUserChanges(scope.selectedUser).then((response) => {

          console.log(response);
          if (response.status == 200) {
   
            // Reset changes
            this.resetUserChanges(scope.selectedUser);
            resolve('Updated successfully')
          } 
          else 
          {
            scope.showErrorMessage(parseError(response));
            reject(response)
          }
        })
      })
    },
    updateAvailableUserDealersNonGroup() {

      let user = this.selectedUser;
      this.availableUserDealers = [];
      this.dealers.forEach((d) => {
        let isPresent = false;
        for (let i = 0; i < user.groupDealers.length; i++) {
          if (user.groupDealers[i].id == d.id) {
            isPresent = true;
            break;
          }
        }
        if (!isPresent) {
          this.availableUserDealers.push(d);
        }
      })
    },
    updateAvailableUserDealerMachines() {
      let user = this.selectedUser;
      user.availableDealerMachines = [];

      let allUserDealers = [];

      user.editDealers.forEach(dealer => {
        let userDealer = allUserDealers.find(d => d.id == dealer.id);
        if (!userDealer) {
          allUserDealers.push(dealer);
        }
      })
      user.editDealerGroups.forEach(group => {
        group.dealers.forEach(dealer => {
          let userDealer = allUserDealers.find(d => d.id == dealer.id);
          if (!userDealer) {
            allUserDealers.push(dealer);
          }
        })
      })
      allUserDealers.forEach(dealer => {
        if (dealer.dealer_machines) {
          dealer.dealer_machines.forEach((m) => {
            if (m.id > 0)
              user.availableDealerMachines.push(m);
            })
        }
      })
      sortByProperties(user.availableDealerMachines, ['name', 'serialNumber']);
      user.availableDealerMachines.unshift({ id: null, name: '' });
    },
    populateDealersTooltips() {
      let user = this.selectedUser;
      user.groupDealers.forEach((d) => {
        let addedGroupNames = [];
    
        user.editDealerGroups.forEach((g) => {
          var foundDealerInGroup = g.dealers.find((gd) => gd.id == d.id);
  
          if (foundDealerInGroup) {
            addedGroupNames.push(g.name);
          }
        })
    
        d.linkedGroups = '';
    
        for (var i = 0; i < addedGroupNames.length; i++) {
          if (i==0) {
            d.linkedGroups += addedGroupNames[i];
          }
          else {
            d.linkedGroups += `, ${addedGroupNames[i]}`;
          }
        }
      })
    },
    setRoles(roles) {
      this.roles = roles;

      roles.forEach((role) => {
        updateFunctionTranslation(role.functions, getLanguage());
      });
    },
    setEngineers(engineers) {

      if (!engineers) {
        engineers = [];
      }

      engineers = engineers.map((eng) => {
        return {
          text: eng.firstname + ' ' + eng.lastname,
          value: eng.id
        }
      })
      sortByProperty(engineers, 'text');

      engineers.splice(0, 0, { text: this.$t('user.installer_none'), value: null })

      this.engineers = engineers;
      console.log('setEngineers', engineers)
    },
    resetUserErrors(user) {
      if (!user) {
        return;
      }
      console.log("Reseting user errors !");
      user.errors = [];
    },
    formatUser(user) {
    
      user.editDealers = JSON.parse(JSON.stringify(user.dealers, null, 2));
      user.editDealerGroups = JSON.parse(JSON.stringify(user.groups, null, 2));
      user.groupDealers = [];
      user.editDealerGroups.forEach((g) => {
        g.dealers.forEach((d) => {
          let isPresent = false;
          for (let i = 0; i < user.groupDealers.length; i++) {
            if (user.groupDealers[i].id == d.id) {
              isPresent = true;
              break;
            }
          }
          if (!isPresent) {
            user.groupDealers.push(d);
          }
        });
      });    
      sortByProperty(user.groupDealers, 'name');

      user.editEngineeringDepartments = JSON.parse(JSON.stringify(user.engineering_departments, null, 2));
      
      user.editRoles = JSON.parse(JSON.stringify(user.roles, null, 2));
      user.functions = [];
      user.editRoles.forEach((r) => {

        updateFunctionTranslation(r.functions, getLanguage());

        r.functions.forEach((f) => {
          let isPresent = false;
          for (let i = 0; i < user.functions.length; i++) {
            if (user.functions[i].id == f.id) {
              isPresent = true;
              break;
            }
          }
          if (!isPresent) {
            user.functions.push(f);
          }
        });
      });
      sortByProperty(user.functions, 'translatedName');
    
      this.populateUserFunctionsTooltips(user)
    
      if (!user.colorCode) {
        user.colorCode = "#000000";
      }

      if(user.welderExpirationDateCwb) {
        let expirationDate = new Date(user.welderExpirationDateCwb);
        user.isWelderExpired = expirationDate && expirationDate < new Date();
      }
    },
    populateUserFunctionsTooltips(user) {
      user.functions.forEach((f) => {
                  
            let addedRoleNames = []
    
            user.editRoles.forEach((r) =>  {
              var foundFunctionInRole = r.functions.find((rf) => rf.id == f.id)
    
              if(foundFunctionInRole){
                addedRoleNames.push(r.name)
              }
            })
    
            f.linkedRoles = ''
            
            for(var i = 0; i < addedRoleNames.length; i++) {
              if(i == 0)
              {
                f.linkedRoles += addedRoleNames[i]
              }
              else
              {
                f.linkedRoles += `, ${addedRoleNames[i]} `
              }
            }
        })
    },
    capitalizeFristLetter: capitalizeFirstLetter,
    onUsernameChange() 
    {   
        let scope = this
        this.isValidatingUsername = true;
        console.log("Typing....")
        clearTimeout(this.usernameValidationTimeout);
        this.usernameValidationTimeout = setTimeout(function(){ 
          
          console.log('Stopped typing.')
          scope.isValidatingUsername = false;
          scope.validateUsername()

        }, 1000);
    },
    async validateUsername() {

      // Don't save if username is invalid
      if (!this.validUsername) {
        return;
      }
      this.usernameErrors = [];
      let data = { username: this.selectedUser.username, id: this.selectedUserId };
      let exists = await UserService.checkUser(data);
      if (exists.data) {
        this.usernameErrors.push(this.$t("dealer.name_exist_already"));
        return;
      }

      this.selectedUser.changes.username = this.selectedUser.username;

      let anyInvalidRole = this.validateHighSecurityRoles(this.$t("user.techno_pieux_email_removed"));
      if (anyInvalidRole) {
        this.saveRoles();
      }

      this.saveUserChanges()
        .then(()=> {
          this.errorSavingUsername = false;
          console.log("Success Username")
        })
        .catch((err)=> {
          console.log(err)
          this.errorSavingUsername = true;
          console.log("Error Username")
        })
        .finally(() => {
          this.isValidatingUsername = false;
        });
    },
    onFirstNameChange() {
      this.selectedUser.firstname = this.capitalizeFristLetter(this.selectedUser.firstname);
      this.selectedUser.changes.firstname = this.selectedUser.firstname;
      this.saveUserChanges();
    },
    onLastNameChange() {
      this.selectedUser.lastname = this.capitalizeFristLetter(this.selectedUser.lastname);
      this.selectedUser.changes.lastname = this.selectedUser.lastname;
      this.saveUserChanges();
    },
    onCommunicationLanguageChange() {
      this.selectedUser.changes.communicationLanguage = this.selectedUser.communicationLanguage;
      this.saveUserChanges();
    },

    isInstallerChanged() {
      this.selectedUser.changes.isInstaller = this.selectedUser.isInstaller;
      this.saveUserChanges();
    },
    isAutoAddedInstallerChanged() {
      this.selectedUser.changes.isAutoAddedInstaller = this.selectedUser.isAutoAddedInstaller;
      this.updateUserAutoAddInstaller(this.selectedUser.isAutoAddedInstaller);
      this.saveUserChanges();
    },
    installerCardNumberChanged(){
      this.selectedUser.changes.installerCardNumber = this.selectedUser.installerCardNumber;
      this.saveUserChanges();
    },
    installerStatusChanged() {
      this.selectedUser.changes.installerStatus = this.selectedUser.installerStatus;
      this.saveUserChanges();
    },
    installerApprovedBy1Changed() {
      if(this.selectedUser.installerApprovedBy1 == null && this.selectedUser.installerApprovedBy2 != null) {
        
        // Si le 1er installateur est mis à null mais qu'il y en a un 2e, le 1er change de place avec le 2e
        this.selectedUser.changes.installerApprovedBy2 = null;
        this.selectedUser.changes.installerApprovedBy1 = this.selectedUser.installerApprovedBy2;

        this.selectedUser.installerApprovedBy1= this.selectedUser.installerApprovedBy2
        this.selectedUser.installerApprovedBy2 = null;
      } else {
        this.selectedUser.changes.installerApprovedBy1 = this.selectedUser.installerApprovedBy1;
      }

      this.saveUserChanges();
    },
    installerApprovedBy2Changed() {
      this.selectedUser.changes.installerApprovedBy2 = this.selectedUser.installerApprovedBy2;
      this.saveUserChanges();
    },

    isWelderChanged() {
      this.selectedUser.changes.isWelder = this.selectedUser.isWelder;
      this.saveUserChanges();
    },
    async sendRequestStatusChange() {
      try {
        let response = await UserService.requestChangeStatus(this.selectedUser, this.requestedInstallerStatus);
        if (response.status == 200) {
          this.selectedUser.installerStatus = this.requestedInstallerStatus;
          this.openToast({ message: this.$t('user.request_installer_status_change_sent'), duration: 5000, color: "green" });
        }
        else {
          this.openToast({ message: response, duration: 15000, color: "red" });
        }
      }
      catch (error) {
        console.log(error);
        this.openToast({ message: error, duration: 15000, color: "red" });
      }
      this.requestInstallerCardDialog = false;
    },
    async sendRequestInstallerCard() {
      try {
        let response = await UserService.requestInstallerCard(this.selectedUser, this.requestedInstallerStatus);
        if (response.status == 200) {
          this.selectedUser.isInstaller = true;
          this.selectedUser.installerStatus = this.requestedInstallerStatus;
          this.openToast({ message: this.$t('user.request_installer_card_sent'), duration: 5000, color: "green" });
        }
        else {
          this.openToast({ message: response, duration: 15000, color: "red" });
        }
      }
      catch (error) {
        console.log(error);
        this.openToast({ message: error, duration: 15000, color: "red" });
      }
      this.requestInstallerCardDialog = false;
    },
    dragOver() {
      this.dropped = 2;
    },
    dragLeave() {},
    async dropPhoto(e) {
      await this.uploadFile({files: e.dataTransfer.files, relatableType: 'users_photo', savingVariable: 'isSavingPhoto', successCallback: this.savePhoto, fromDrop: true});
      e.dataTransfer.value = '';
    },
    async dropInstallerCard(e) {
      if (!this.canModifyInstallers) return;
      await this.uploadFile({files: e.dataTransfer.files, relatableType: 'users_installer_card', savingVariable: 'isSavingInstallerCard', successCallback: this.saveInstallerCard, fromDrop: true});
      e.dataTransfer.value = '';
    },
    async dropWelderCard(e) {
      if (!this.canManageDealers || (!this.selectedUser.multipleWelderCardImages && this.selectedUser.welderCardCwbs.length > 0)) return;
      await this.uploadFile({files: e.dataTransfer.files, relatableType: 'users_cwb_card', savingVariable: 'isSavingWelderCard', successCallback: this.saveWelderCard, allowMultiple: true, allowPdf: true, fromDrop: true});
      e.dataTransfer.value = '';
    },
    async handleWelderCardUpload() { // Validation done in the drop functions are not needed for the button because it is not displayed
      await this.uploadFile({files: this.$refs.welderCardCwb.files, relatableType: 'users_cwb_card', savingVariable: 'isSavingWelderCard', successCallback: this.saveWelderCard, allowMultiple: true, allowPdf: true});
      this.$refs.welderCardCwb.value = '';
    },
    async handleInstallerCardUpload() { // Validation done in the drop functions are not needed for the button because it is not displayed
      await this.uploadFile({files: this.$refs.installerCard.files, relatableType: 'users_installer_card', savingVariable: 'isSavingInstallerCard', successCallback: this.saveInstallerCard});
      this.$refs.installerCard.value = '';
    },
    async handlePhotoUpload() { // Validation done in the drop functions are not needed for the button because it is not displayed
      await this.uploadFile({files: this.$refs.photo.files, relatableType: 'users_photo', savingVariable: 'isSavingPhoto', successCallback: this.savePhoto});
      this.$refs.photo = '';
    },
    //data: {files, relatableType, savingVariable, successCallback, [fromDrop, allowPdf, allowMultiple]}
    async uploadFile(data) {
      if (!data.files) {
        if (data.fromDrop) this.dropped = 0;
        return;
      }
      let files = Array.from(data.files);
      if (files?.length < 1) {
        if (data.fromDrop) this.dropped = 0;
        return;
      }

      if (!data.allowMultiple) files = [files[0]];

      for (let file of files) {
        if (file.type.startsWith("image") || file.name.toLowerCase().endsWith(".heic") || (data.allowPdf && file.type.endsWith("pdf"))) {
          if (this.savingFile) return;
          this.savingFile = true;
          this[data.savingVariable] = true;
          this.$forceUpdate();
          let resp = await FileService.addNewFile(file, data.relatableType, this.selectedUser.id);
          this[data.savingVariable] = false;
          this.savingFile = false;
          if (resp.data?.alreadyExists === true) this.openToast({ message: this.$t('reportInfo.file_already_exists'), duration: 3000}); // Not supposed to happen, as files uploaded for the user are all unique, so the server deletes the one existing and adds tis one.
          else if (!resp.success) this.openToast({ message: resp.data, duration: 5000, color: 'red'});
          else data.successCallback(resp);
        }
      }
      if (data.fromDrop) this.dropped = 0;
      this.$forceUpdate();
    },
    async savePhoto(resp) {
      this.selectedUser.photo = resp.data;
      this.hasSelectedFile = false;
    },
    async saveInstallerCard(resp) {
      this.selectedUser.installerCard = resp.data;
      this.hasSelectedFile = false;
    },
    async saveWelderCard(resp) {
      this.selectedUser.welderCardCwbs.push(resp.data);
      this.hasSelectedFile = false;
    },
    confirmPhotoDelete() {
      this.confirmDialog = true;
      this.confirmDialogTitle = this.$t('confirm.head');
      this.confirmDialogBody = this.$t('confirm.file_message');
      this.confirmAction = ConfirmAction.DeletePhoto;
      this.confirmYesNo = true;
    },
    async deletePhoto() {
      let resp = await FileService.deleteFile(this.selectedUser.photo);
      if (resp.success) this.selectedUser.photo = null;
      else this.openToast({ message: resp.data, duration: 5000, color: 'red'});
      this.$forceUpdate();
    },
    confirmInstallerCardDelete() {
      this.confirmDialog = true;
      this.confirmDialogTitle = this.$t('confirm.head');
      this.confirmDialogBody = this.$t('confirm.file_message');
      this.confirmAction = ConfirmAction.DeleteInstallerCard;
      this.confirmYesNo = true;
    },
    async deleteInstallerCard() {
      let resp = await FileService.deleteFile(this.selectedUser.installerCard);
      if (resp.success) this.selectedUser.installerCard = null;
      else this.openToast({ message: resp.data, duration: 5000, color: 'red'});
      this.$forceUpdate();
    },
    confirmWelderCardDelete(index) {
      this.welderCardToDeleteIndex = index;
      this.confirmDialog = true;
      this.confirmDialogTitle = this.$t('confirm.head');
      this.confirmDialogBody = this.$t('confirm.file_message');
      this.confirmAction = ConfirmAction.DeleteWelderCard;
      this.confirmYesNo = true;
    },
    async deleteWelderCard() {
      let welderCardToDelete = this.selectedUser.welderCardCwbs[this.welderCardToDeleteIndex];
      let resp = await FileService.deleteFile(welderCardToDelete);
      if (resp.success) this.selectedUser.welderCardCwbs.splice(this.welderCardToDeleteIndex, 1);
      else this.openToast({ message: resp.data, duration: 5000, color: 'red'});
      this.$forceUpdate();
    },
    emailMainChanged() {
      if (this.validEmails) {
        this.selectedUser.changes.emailMain = this.selectedUser.emailMain;
        this.saveUserChanges();
      }
    },
    emailPersonalChanged() {
      if (this.validEmails) {
        this.selectedUser.changes.emailPersonal = this.selectedUser.emailPersonal;
        this.saveUserChanges();
      }
    },
    emailOtherChanged() {
      if (this.validEmails) {
        this.selectedUser.changes.emailOther = this.selectedUser.emailOther;
        this.saveUserChanges();
      }
    },
    phoneMobileChanged() {
      this.selectedUser.changes.phoneMobile = this.selectedUser.phoneMobile;
      this.saveUserChanges();
    },
    phoneOfficeChanged() {
      this.selectedUser.changes.phoneOffice = this.selectedUser.phoneOffice;
      this.saveUserChanges();
    },
    colorChanged() {
      if (this.selectedUser.id == this.currentUser.id) {
        this.currentUser.colorCode = this.selectedUser.colorCode;
      }
      this.selectedUser.changes.colorCode = this.selectedUser.colorCode;
      this.saveUserChanges();
    },
    applyActiveChange() {
      this.selectedUser.changes.isActive = this.selectedUser.isActive;
      this.saveUserChanges();
    },
    isActiveChanged() {

      if (!this.selectedUser.isActive && this.currentUser.id == this.selectedUser.id) {
        // Reset the flag temporarly until the user accepts
        var scope = this;
        setTimeout(function(){ 
          scope.selectedUser.isActive = true;
        }, 100);

        this.confirmDialog = true;
        this.confirmDialogTitle = this.$t("confirm.head");
        this.confirmDialogBody = this.$t("confirm.disable_own_user");
        this.confirmAction = ConfirmAction.DisableOwnUser;
        this.confirmYesNo = true;
      }
      else {
        this.applyActiveChange();
      }
    },
    dealerMachineChanged() {
      this.selectedUser.changes.dealerMachineId = this.selectedUser.dealerMachineId;
      this.saveUserChanges();
    },
    dealerChanged() {

      // Si on n'est pas un administrateur, 
      if (!this.canManageSecurity) {

        // on ne peut pas désassigner le dernier concessionnaire d'un usager
        let selectedDealers = this.selectedUser.editDealers.filter(ed => !this.selectedUser.groupDealers.find(gd => ed.id == gd.id));
        //console.log('selectedDealers', selectedDealers)

        let initialSelectedDealers = this.selectedUser.dealers.filter(ed => !this.selectedUser.groupDealers.find(gd => ed.id == gd.id));
        //console.log('initialSelectedDealers', initialSelectedDealers)

        if (selectedDealers.length == 0 && initialSelectedDealers.length > 0) {
          this.selectedUser.editDealers.push(initialSelectedDealers[0]);
          this.openToast({ message: this.$t("user.minimum_one_dealer"), duration: 10000, color: "red" });
          return;
        }
      }

      this.selectedUser.changes.addedDealers = [];
      this.selectedUser.changes.deletedDealers = [];

      this.selectedUser.editDealers.forEach((ed) => {
        let isPresent = false;
        for (let i = 0; i < this.selectedUser.dealers.length; i++) {
          if (this.selectedUser.dealers[i].id == ed.id) {
            isPresent = true;
            break;
          }
        }
        if (!isPresent) {
          this.selectedUser.changes.addedDealers.push(ed.id);
        }
      });

      this.selectedUser.dealers.forEach((d) => {
        let isPresent = false;
        for (let i = 0; i < this.selectedUser.editDealers.length; i++) {
          if (this.selectedUser.editDealers[i].id == d.id) {
            isPresent = true;
            break;
          }
        }
        if (!isPresent) {
          this.selectedUser.changes.deletedDealers.push(d.id);
        }
      });

      this.saveUserChanges();
      this.selectedUser.dealers = this.selectedUser.editDealers;

      this.updateDealerGroups();
      this.$forceUpdate();
    },
    dealerGroupChanged() {
      this.selectedUser.groupDealers = [];
      this.selectedUser.editDealerGroups.forEach((g) => {
        g.dealers.forEach((d) => {
          let isPresent = false;
          for (let i = 0; i < this.selectedUser.groupDealers.length; i++ ) {
            if (this.selectedUser.groupDealers[i].id == d.id) {
              isPresent = true;
              break;
            }
          }
          if (!isPresent) {
            this.selectedUser.groupDealers.push(d);
          }
        })
      })

      sortByProperty(this.selectedUser.groupDealers, 'name');
      
      this.$forceUpdate();

      this.selectedUser.changes.addedDealerGroups = [];
      this.selectedUser.changes.deletedDealerGroups = [];

      this.selectedUser.editDealerGroups.forEach((eg) => {
        let isPresent = false;
        for (let i = 0; i < this.selectedUser.groups.length; i++) {
          if (this.selectedUser.groups[i].id == eg.id) {
            isPresent = true;
            break;
          }
        }
        if (!isPresent) {
          this.selectedUser.changes.addedDealerGroups.push(eg.id);
        }
      });

      this.selectedUser.groups.forEach((g) => {
        let isPresent = false;
        for (let i = 0; i < this.selectedUser.editDealerGroups.length; i++) {
          if (this.selectedUser.editDealerGroups[i].id == g.id) {
            isPresent = true;
            break;
          }
        }
        if (!isPresent) {
          this.selectedUser.changes.deletedDealerGroups.push(g.id);
        }
      })

      this.saveUserChanges();
      this.selectedUser.groups = this.selectedUser.editDealerGroups;

      this.updateDealerGroups();
    },
    engineeringDepartmentsChanged() {
      this.selectedUser.changes.addedEngineeringDepartments = [];
      this.selectedUser.changes.deletedEngineeringDepartments = [];

      this.selectedUser.editEngineeringDepartments.forEach((eg) => {
        let isPresent = false;
        for (let i = 0; i < this.selectedUser.engineering_departments.length; i++) {
          if (this.selectedUser.engineering_departments[i].id == eg.id) {
            isPresent = true;
            break;
          }
        }
        if (!isPresent) {
          this.selectedUser.changes.addedEngineeringDepartments.push(eg.id);
        }
      });

      this.selectedUser.engineering_departments.forEach((g) => {
        let isPresent = false;
        for (let i = 0; i < this.selectedUser.editEngineeringDepartments.length; i++) {
          if (this.selectedUser.editEngineeringDepartments[i].id == g.id) {
            isPresent = true;
            break;
          }
        }
        if (!isPresent) {
          this.selectedUser.changes.deletedEngineeringDepartments.push(g.id);
        }
      })

      this.saveUserChanges();
      this.selectedUser.engineering_departments = this.selectedUser.editEngineeringDepartments;
    },
    validateHighSecurityRoles(messageToDisplay) {
      
      let isTPEmail = isTechnoPieuxEmail(this.selectedUser.username);

      // C1327-401 - Seulement les courriels @technopieux.com et @technometalpost.com peuvent être utilisés pour les leaders, admins, ing.
      let anyInvalidRole = false;

      this.selectedUser.editRoles = this.selectedUser.editRoles.filter(function(r) {

        let isHighSecurityRole = false;
        switch (r.id) {
           case ROLES.ADMIN:
           case ROLES.ENGINEER:
           case ROLES.LEADER:
           case ROLES.ENGINEER_ADMIN:
             isHighSecurityRole = true;
             break;
           default:
              break;
        }
        let isInvalidRole = isHighSecurityRole && !isTPEmail;
        if (isInvalidRole) {
          anyInvalidRole = true;
        }
        return !isInvalidRole;
      });

      if (anyInvalidRole) {
        this.openToast({ message: messageToDisplay, duration: 10000, color: "red" });
      }
      return anyInvalidRole;
    },
    saveRoles() {
      this.selectedUser.functions = [];
      this.selectedUser.editRoles.forEach((r) => {
        r.functions.forEach((f) => {
          let isPresent = false;
          for (let i = 0; i < this.selectedUser.functions.length; i++) {
            if (this.selectedUser.functions[i].id == f.id) {
              isPresent = true;
              break;
            }
          }
          if (!isPresent) {
            this.selectedUser.functions.push(f);
          }
        });
      });
      this.$refs.emailInput.focus();
      this.$refs.usernameForm.validate();

      sortByProperty(this.selectedUser.functions, 'translatedName')
      
      this.$forceUpdate();

      this.selectedUser.changes.addedRoles = [];
      this.selectedUser.changes.deletedRoles = [];

      this.selectedUser.editRoles.forEach((ed) => {
        let isPresent = false;
        for (let i = 0; i < this.selectedUser.roles.length; i++) {
          if (this.selectedUser.roles[i].id == ed.id) {
            isPresent = true;
            break;
          }
        }
        if (!isPresent) {
          this.selectedUser.changes.addedRoles.push(ed.id);
        }
      });

      this.selectedUser.roles.forEach((d) => {
        let isPresent = false;
        for (let i = 0; i < this.selectedUser.editRoles.length; i++) {
          if (this.selectedUser.editRoles[i].id == d.id) {
            isPresent = true;
            break;
          }
        }
        if (!isPresent) {
          this.selectedUser.changes.deletedRoles.push(d.id);
        }
      });
    
      this.saveUserChanges();
      this.selectedUser.roles = this.selectedUser.editRoles;
      
      this.populateUserFunctionsTooltips(this.selectedUser)
    },
    roleChanged() {

      this.validateHighSecurityRoles(this.$t("user.techno_pieux_email_required"));      
      this.saveRoles();
    },

    async updateInstallerFormationDate(save) {
      this.selectedUser.changes.installerFormationDate = this.selectedUser.installerFormationDate;
      
      if (save) {
        this.saveUserChanges();
      }
    },

    async updateInstallerCardLastImpression(save) {
      this.selectedUser.changes.installerCardLastImpression = this.selectedUser.installerCardLastImpression;

      if(save) {
        this.saveUserChanges();
      }
    },

    async updateWelderExpirationDate(save) {
      this.selectedUser.changes.welderExpirationDateCwb = this.selectedUser.welderExpirationDateCwb;
      
      let expirationDate = new Date(this.selectedUser.welderExpirationDateCwb);
      this.selectedUser.isWelderExpired =
        expirationDate && expirationDate < new Date();
      if (save) {
        this.saveUserChanges();
      }
    },

    async sendNewPassword() {
      let response = await UserService.sendNewAccountEmail(this.selectedUser, getLanguage());

      if (response && response.status == 200) {
        this.selectedUser.passwordSentAt = new Date();
        this.showConfirmation = true;
      }
      else {
        this.openToast({ message: response, duration: 15000, color: "red" });
      }      
    },

    yyyymmdd: yyyymmdd,
    yyyymmddhhmm: yyyymmddhhmm,

    confirmDialogYes() {
      switch (this.confirmAction) {
        case ConfirmAction.DeleteWelderCard:
          this.deleteWelderCard();
          break;
        case ConfirmAction.DeleteInstallerCard:
          this.deleteInstallerCard();
          break;
        case ConfirmAction.DeletePhoto:
          this.deletePhoto();
          break;
        case ConfirmAction.DisableOwnUser:
          this.selectedUser.isActive = false;
          this.applyActiveChange();
          this.logout();
          break;
        default:
          break;
      }
      this.confirmDialog = false;
    },
    logout() {
      this.$store.dispatch("logout", this.$socket);
      this.$router.push("/login");
    },
    initializeUser(userId) {
      console.log('initializeUser', userId)

      if (this.$refs && this.$refs.form) {
        this.$refs.form.resetValidation();
      }

      this.$store.dispatch("setBreadcrumbs", [
        { text: this.$t("users.users"), href: "/users" },
        {
          text: this.$t("user.user") + " # " + userId,
          href: "/user/" + userId,
        },
      ]);
      this.setSelectedUserAction(userId);
    }
  },
};
</script>

<style scoped>
label {
  color: rgba(0, 0, 0, 0.6);
}
.alternate {
  padding: 3px;
  padding-right: 16px;

  border-radius: 5px;

  background: #e8e4e47a;
}
.small-text {
  font-size:12px;
}
.requests-anchor {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  position: relative;
  display: inline-block; 
  height: 48px;
  width: 100%;
}
.requests-anchor img {
  display: inline-block;
  height: 100%;
}
.request-container {
  position: relative;
}
.request-container + .request-container {
  margin-top: 5px;
}
.wrappedfile {
  text-overflow: ellipsis !important;
  max-width: 49%;
  margin-left: 10px;
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  line-height: 15px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  white-space: nowrap;
}
.required-img{
  width:48px;
  height:48px;
  border:1px dashed black; 
  border-radius:8px;
}
.upload-btn-wrapper {
  position: absolute;
  right: 0px;
  top: 0px;
}

.upload-btn-wrapper button {
  box-shadow: none;
  height: 50px;
  width: 50px;
}
.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.delete-photo-icon{
  position:absolute;
  top:10px;
  right:0px;
}
</style>
